<template>
  <div class="detail">
    <div class="detail-con">
      <!-- 返回按钮 -->
      <div class="detail-con-top flex align-center justify-between">
        <div class="detail-top-left flex align-center">
          <div class="detail-top-left-shu"></div>
          <div class="detail-top-left-text">行业管理详表</div>
        </div>
        <div
          @click="back()"
          class="detail-back flex justify-center align-center"
        >
          <img
            src="../../../assets/img/layout/back.png"
            class="detail-back-img"
          />
          <div class="detail-back-text">
            返回
          </div>
        </div>
      </div>

      <!-- 表格上面内容 -->
      <div class="detail-top flex justify-between align-center">
        <div class="detail-top-left flex align-center">
          <!-- <div class="detail-top-left-shu"></div>
          <div class="detail-top-left-text">行业管理详表</div> -->
        </div>
        <div class="detail-top-right flex align-center">
          <a-button
            type="primary"
            class="detail-top-right-left"
            @click="router('/index/industry/index/focusDetail')"
          >
            查看供水工程
          </a-button>
          <a-button
            type="primary"
            class="detail-top-right-left"
            @click="selectAll(1)"
            v-if="identity == 0 || identity == 1"
          >
            查看全省所有的县
          </a-button>
          <a-button
            type="primary"
            class="detail-top-right-left"
            @click="selectAll(0)"
            v-if="identity == 2"
          >
            查看全市所有的县
          </a-button>
          <a-button type="primary">
            导出
          </a-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="detail-table">
        <div class="detail-table-con" id="ids">
          <div class="detail-table-content">
            <div class="detail-table-content-top flex align-center ">
              <!--              <div-->
              <!--                class="detail-table-content-itemA width80 flex align-center justify-center"-->
              <!--              >-->
              <!--                <div class="detail-table-content-itemA-con">上报年度</div>-->
              <!--              </div>-->
              <!--              <div-->
              <!--                class="detail-table-content-itemA width80 flex align-center justify-center"-->
              <!--              >-->
              <!--                <div class="detail-table-content-itemA-con">-->
              <!--                  省（自治区、直辖市）市、县级名称-->
              <!--                </div>-->
              <!--              </div>-->
              <div
                class="detail-table-content-items"
                v-for="(item, index) in columns"
                :key="index"
              >
                <div
                  v-if="!item.children"
                  class="detail-table-content-itemA flex align-center justify-center relative"
                  :style="`width: ${item.width}px`"
                >
                  <div class="detail-table-content-itemA-con">
                    {{ item.title }}
                  </div>
                  <!-- <img
                    @click="activefn(index, false)"
                    src="../../../assets/img/layout/active.png"
                    class="table-active absolute"
                    v-if="item.active"
                  />
                  <img
                    @click="activefn(index, true)"
                    src="../../../assets/img/layout/noActive.png"
                    class="table-active absolute"
                    v-else
                  /> -->
                </div>
                <div v-else class="detail-table-content-itemB relative">
                  <div class="detail-table-content-itemB-top">
                    {{ item.title }}
                  </div>
                  <div
                    class="detail-table-content-itemB-bottom flex align-center"
                  >
                    <div
                      v-for="(i, indexi) in item.children"
                      :key="indexi"
                      class="detail-table-content-itemB-bottom-item flex align-center justify-center"
                    >
                      <div
                        v-html="i.name"
                        class="detail-table-content-itemB-bottom-item-text"
                      >
                        <!-- {{ i.name }} -->
                      </div>
                    </div>
                  </div>
                  <!-- <img
                    @click="activefn(index, false)"
                    src="../../../assets/img/layout/active.png"
                    class="table-activeB absolute"
                    v-if="item.active"
                  />
                  <img
                    @click="activefn(index, true)"
                    src="../../../assets/img/layout/noActive.png"
                    class="table-activeB absolute"
                    v-else
                  /> -->
                </div>
              </div>
              <!--              <div-->
              <!--                class="detail-table-content-itemA width80 flex align-center justify-center"-->
              <!--              >-->
              <!--                <div class="detail-table-content-itemA-con">-->
              <!--                  规模化供水率（%）-->
              <!--                </div>-->
              <!--              </div>-->
              <!-- 上下级类型开始 -->
              <!-- <div class="detail-table-content-itemB relative">
                <div class="detail-table-content-itemB-top">
                  集中供水工程
                </div>
                <div
                  class="detail-table-content-itemB-bottom flex align-center"
                >
                  <div
                    class="detail-table-content-itemB-bottom-item flex align-center justify-center"
                  >
                    <div class="detail-table-content-itemB-bottom-item-text">
                      供水人数<br />（万人）
                    </div>
                  </div>
                  <div
                    class="detail-table-content-itemB-bottom-item flex align-center justify-center"
                  >
                    <div class="detail-table-content-itemB-bottom-item-text">
                      工程处数 （处）
                    </div>
                  </div>
                </div>
                <img
                  src="../../../assets/img/layout/active.png"
                  class="table-activeB absolute"
                  v-if="false"
                />
                <img
                  src="../../../assets/img/layout/noActive.png"
                  class="table-activeB absolute"
                  v-if="true"
                />
              </div> -->
              <!-- 上下级类型结束 -->
            </div>
            <div
              class="detail-table-content-list flex align-center"
              v-for="(item, index) in list"
              :key="index"
            >
              <div
                class="detail-table-content-list-item"
                :style="`width: ${item2.width}px`"
                v-for="(item2, index2) in item"
                :key="index2"
              >
                {{ item2.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIndustrieList } from "@/api/industry/index";
import { showArea, getDefaultArea } from "@/api/area/index";
export default {
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "num",
          key: "num",
          width: 50,
          active: false
        },
        {
          title: "上报年度",
          dataIndex: "reportYear",
          key: "reportYear",
          width: 90,
          active: false
        },
        {
          title: "省（自治区、直辖市）市、县级名称",
          dataIndex: "adName",
          key: "adName",
          width: 90,
          active: false
        },
        {
          title: "区县数量 （个）",
          dataIndex: "numCountry",
          key: "numCountry",
          width: 90,
          active: false
        },
        {
          title: "总人口 （万人）",
          dataIndex: "cntyTotPop",
          key: "cntyTotPop",
          width: 90,
          active: false
        },
        {
          title: "农村供水 总人口 （万人）",
          dataIndex: "cntyTotcounPop",
          key: "cntyTotcounPop",
          width: 90,
          active: false
        },
        {
          title: "乡镇数量 （个）",
          dataIndex: "numTown",
          key: "numTown",
          width: 90,
          active: false
        },
        {
          title: "行政村 数量 （个）",
          dataIndex: "numVillage",
          key: "numVillage",
          width: 90,
          active: false
        },
        {
          title: "农村饮水安全项目县",
          dataIndex: "cenDrinksafeCnty",
          key: "cenDrinksafeCnty",
          width: 90,
          active: false
        },
        {
          title: "所属流域 机构（每 个县只能 属于一个 流域机构）",
          dataIndex: "address",
          key: "address",
          width: 90,
          active: false
        },
        {
          title: "集中供水工程",
          dataIndex: "name",
          key: "name",
          width: 160,
          active: false,
          children: [
            {
              name: "供水人数<br />（万人）",
              dataIndex: "peoCwse",
              key: "peoCwse",
              width: 80
            },
            {
              name: "工程处数 （处）",
              dataIndex: "plaCwse",
              key: "plaCwse",
              width: 80
            }
          ]
        },
        {
          title: "城市管网延伸工程",
          dataIndex: "name",
          key: "name",
          width: 160,
          active: false,
          children: [
            {
              name: "供水人数<br />（万人）",
              dataIndex: "popCityWasu",
              key: "popCityWasu",
              width: 80
            },
            {
              name: "工程处数 （处）",
              dataIndex: "plaCityWasu",
              key: "plaCityWasu",
              width: 80
            }
          ]
        },
        {
          title: "万人工程",
          dataIndex: "name",
          key: "name",
          width: 160,
          active: false,
          children: [
            {
              name: "供水人数<br />（万人）",
              dataIndex: "popQdwrWasu",
              key: "popQdwrWasu",
              width: 80
            },
            {
              name: "工程处数 （处）",
              dataIndex: "plaQdwrWasu",
              key: "plaQdwrWasu",
              width: 80
            }
          ]
        },
        {
          title: "千人工程",
          dataIndex: "name",
          key: "name",
          width: 160,
          active: false,
          children: [
            {
              name: "供水人数<br />（万人）",
              dataIndex: "popQdWasu",
              key: "popQdWasu",
              width: 80
            },
            {
              name: "工程处数 （处）",
              dataIndex: "plaQdWasu",
              key: "plaQdWasu",
              width: 80
            }
          ]
        },
        {
          title: "千人以下工程",
          dataIndex: "name",
          key: "name",
          width: 120,
          active: false,
          children: [
            {
              name: "供水人数<br />（万人）",
              dataIndex: "popQrWasu",
              key: "popQrWasu", //popFosWasu
              width: 80
            },
            {
              name: "工程处数 （处）",
              dataIndex: "plaQrWasu",
              key: "plaQrWasu", //plaFosWasu
              width: 80
            },
            {
              name: "百人以上 供水人数 （万人）",
              dataIndex: "popFosWasu",
              key: "popFosWasu",
              width: 80
            },
            {
              name: "百人以上 供水处数 （处）",
              dataIndex: "plaFosWasu",
              key: "plaFosWasu",
              width: 80
            },
            {
              name: "百人以下 供水人数 （万人）",
              dataIndex: "popHundWasu",
              key: "popHundWasu",
              width: 80
            },
            {
              name: "百人以下 供水处数 （处）",
              dataIndex: "plaHundWasu",
              key: "plaHundWasu",
              width: 80
            }
          ]
        },
        {
          title: "分散工程",
          dataIndex: "name",
          key: "name",
          width: 120,
          active: false,
          children: [
            {
              name: "供水人数<br />（万人）",
              dataIndex: "peoDwse",
              key: "peoDwse",
              width: 80
            },
            {
              name: "工程处数 （处）",
              dataIndex: "plaDwse",
              key: "plaDwse",
              width: 80
            },
            {
              name: "浅井 供水人数 （万人）",
              dataIndex: "popDisShaWasu",
              key: "popDisShaWasu",
              width: 80
            },
            {
              name: "浅井 供水处数 （处）",
              dataIndex: "plaDisShaWasu",
              key: "plaDisShaWasu",
              width: 80
            },
            {
              name: "雨水蓄积 供水人数 （万人）",
              dataIndex: "popDisRainWasu",
              key: "popDisRainWasu",
              width: 80
            },
            {
              name: "雨水蓄积 工程处数 （处）",
              dataIndex: "plaDisRainWasu",
              key: "plaDisRainWasu",
              width: 80
            },
            {
              name: "引泉供水人数（万人）",
              dataIndex: "popDisLeadWasu",
              key: "popDisLeadWasu",
              width: 80
            },
            {
              name: "引泉工程处数 （处）",
              dataIndex: "plaDisLeadWasu",
              key: "plaDisLeadWasu",
              width: 80
            },
            {
              name: "无设施供水人数（万人）",
              dataIndex: "popDisNofacWasu",
              key: "popDisNofacWasu",
              width: 80
            }
          ]
        }
      ],
      year: "",
      areaId: 0,
      areaName: "",
      identity: 0,
      list: [
        //   {
        //     name: "山西",
        //     num: 99.99
        //   },
        //   {
        //     name: "太原",
        //     num: 99.99
        //   },
        //   {
        //     name: "太原",
        //     num: 99.99
        //   },
        //   {
        //     name: "山西",
        //     num: 99.99
        //   },
        //   {
        //     name: "太原",
        //     num: 99.99
        //   },
        //   {
        //     name: "太原",
        //     num: 99.99
        //   },
        //   {
        //     name: "山西",
        //     num: 99.99
        //   },
        //   {
        //     name: "太原",
        //     num: 99.99
        //   },
        //   {
        //     name: "太原",
        //     num: 99.99
        //   }
      ]
    };
  },
  created() {
    if (this.$route.query.year) {
      this.year = this.$route.query.year;
    }
    if (this.$route.query.id) {
      this.areaId = this.$route.query.id;
    }
    this.getList({
      areaId: this.$route.query.id,
      queryBy: 0,
      reportYear: this.$route.query.year
    });

    let data = getDefaultArea();
    this.identity = data.identity;
  },
  mounted() {},
  methods: {
    // 导出excel
    getExcel(list, name) {
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/assets/Export2Excel.js");
        const tHeader = ["资产名称", "开始使用时间", "质保期", "备注"];
        const filterVal = ["name", "start_use_time", "end_use_time", "remark"];
        const data = list.map(v => filterVal.map(j => v[j]));
        export_json_to_excel(tHeader, data, name);
      });
    },
    download() {
      // this.downloadLoading = true
      import("@/assets/Export2Excel").then(excel => {
        const multiHeader = [
          [
            "上报年度",
            "省（自治区、直辖市）市、县级名称",
            "区县数量 （个）",
            "总人口 （万人）",
            "农村供水 总人口 （万人）",
            "乡镇数量 （个）",
            "行政村 数量 （个）",
            "农村饮水安全项目县（是/否）",
            "所属流域 机构（每 个县只能 属于一个 流域机构）",
            "集中供水工程",
            "",
            ""
          ]
        ];
        const tHeader = [
          [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "供水人数（万人）",
            "工程处数 （处）"
          ]
        ];
        const filterVal = [
          "",
          "adName",
          "numCountry",
          "cntyTotPop",
          "cntyTotcounPop",
          "numTown",
          "numVillage",
          "num",
          "num",
          "peoCwse",
          "plaCwse"
        ];
        // let list = [];
        // if (this.tableDataExcelType == 0) {
        //   list = this.tableDataExcel;
        // } else {
        //   list = this.tableDataExcelAll;
        // }
        const data = this.formatJson(filterVal, this.list);
        //进行所有表头的单元格合并，建议一行一行来，不然容易整乱
        const merges = [
          "A1:A2",
          "B1:B2",
          "C1:C2",
          "D1:D2",
          "E1:E2",
          "F1:F2",
          "G1:G2",
          "H1:H2",
          "I1:I2",
          "J1:K1"
        ];
        //  const merges = ['A1:A2', 'B1:B2', 'C1:I2','J1:P2']

        excel.export_json_to_excel({
          multiHeader, //这里是第一行的表头
          header: tHeader, //这里应该是算第二行的表头
          data, //数据
          merges, //合并行
          filename: "行业管理详表" + "_" + new Date().getTime(),
          autoWidth: true,
          bookType: "xlsx"
        });
        // this.downloadLoading = false
      });
    },
    //json格式化
    formatJson(filterVal, jsonData) {
      console.log(jsonData);
      // jsonData.map(v => filterVal.map(j => v[j]));
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    // download() {
    //   this.getExcel(this.list, "行业管理详表");
    // },
    //调用
    getList(data) {
      this.list = [];
      getIndustrieList(data).then(res => {
        if (res.code == 200) {
          // 表头权限
          let data_permissions = JSON.parse(
            localStorage.getItem("data_permissions")
          );
          let admin = localStorage.getItem("admin");
          // 超级管理员
          if (admin == "1") {
            this.columns.push(
              {
                title: "集中供水率（%）",
                dataIndex: "focusWasePow",
                key: "focusWasePow",
                width: 90,
                active: false
              },
              {
                title: "自来水普及率（%）",
                dataIndex: "wawPow",
                key: "wawPow",
                width: 90,
                active: false
              },
              {
                title: "供水保证率",
                dataIndex: "endPow",
                key: "endPow",
                width: 160,
                active: false,
                children: [
                  {
                    name: "规模化供水工程保证率达标程度（%）",
                    dataIndex: "scaleEngRate",
                    key: "scaleEngRate",
                    width: 80
                  },
                  {
                    name: "小型供水工程供水保证率达标程度（%）",
                    dataIndex: "smallEngRate",
                    key: "smallEngRate",
                    width: 80
                  }
                ]
              },
              {
                title: "城镇自来水管网覆盖行政村人口的比例（%）",
                dataIndex: "netwContRate",
                key: "netwContRate",
                width: 90,
                active: false
              },
              {
                title: "规模化供水率（%）",
                dataIndex: "scalePow",
                key: "scalePow",
                width: 90,
                active: false
              }
            );
          } else {
            // 四率（五率权限）
            let rate = data_permissions[0];
            if (rate && rate.checked) {
              for (let i in rate.children) {
                let result = rate.children[i];
                if (result.checked && result.column != "qualityPow") {
                  // 集中供水率
                  if (result.column == "focusWasePow") {
                    this.columns.push({
                      title: "集中供水率（%）",
                      dataIndex: "focusWasePow",
                      key: "focusWasePow",
                      width: 90,
                      active: false
                    });
                    continue;
                  }
                  // 自来水普及率
                  if (result.column == "wawPow") {
                    this.columns.push({
                      title: "自来水普及率（%）",
                      dataIndex: "wawPow",
                      key: "wawPow",
                      width: 90,
                      active: false
                    });
                    continue;
                  }
                  // 供水保证率
                  if (result.column == "endPow") {
                    this.columns.push({
                      title: "供水保证率",
                      dataIndex: "endPow",
                      key: "endPow",
                      width: 160,
                      active: false,
                      children: [
                        {
                          name: "规模化供水工程保证率达标程度（%）",
                          dataIndex: "scaleEngRate",
                          key: "scaleEngRate",
                          width: 80
                        },
                        {
                          name: "小型供水工程供水保证率达标程度（%）",
                          dataIndex: "smallEngRate",
                          key: "smallEngRate",
                          width: 80
                        }
                      ]
                    });
                    continue;
                  }
                  // 规模化供水率
                  if (result.column == "scalePow") {
                    this.columns.push({
                      title: "规模化供水率（%）",
                      dataIndex: "scalePow",
                      key: "scalePow",
                      width: 90,
                      active: false
                    });
                    continue;
                  }
                }
              }
            }
            this.columns.push({
              title: "城镇自来水管网覆盖行政村人口的比例（%）",
              dataIndex: "netwContRate",
              key: "netwContRate",
              width: 90,
              active: false
            });
          }

          // 根据表头渲染数据
          for (let j in res.data.list) {
            let r = res.data.list[j];
            let data = [];
            for (let k in this.columns) {
              let cos = this.columns[k];
              if (cos.key == "num") {
                data.push({
                  column: cos.key,
                  value: Number(j) + 1,
                  width: cos.width
                });
              } else if (cos.key == "reportYear") {
                data.push({
                  column: cos.key,
                  value: this.year,
                  width: cos.width
                });
              } else {
                if (cos.children) {
                  for (let m in cos.children) {
                    let child = cos.children[m];
                    data.push({
                      column: child.key,
                      value: r[child.key]
                        ? r[child.key]
                        : r.consumProjectPeople[child.key],
                      width: child.width
                    });
                  }
                } else {
                  data.push({
                    column: cos.key,
                    value: r[cos.key]
                      ? r[cos.key]
                      : r.consumProjectPeople[cos.key],
                    width: cos.width
                  });
                }
              }
            }
            this.list.push(data);
            console.log(this.list);
          }
        }
      });
    },
    selectAll(queryBy) {
      this.getList({
        areaId: this.$route.query.id,
        queryBy: queryBy,
        reportYear: this.$route.query.year
      });
    },
    back() {
      this.$router.go(-1);
    },
    //跳转
    router(x) {
      this.$router.push({
        path: x
      });
    },
    activefn(index, active) {
      console.log(1111111);
      this.columns[index].active = active;
    }
  }
};
</script>

<style scoped lang="less">
.detail {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 30px;
  box-sizing: border-box;
  overflow: auto;
}
.detail-con {
  width: 100%;
  height: 860px;
  padding: 30px 20px;
  box-sizing: border-box;
  background: #ffffff;
  position: relative;
}
.detail-back {
  width: 80px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  margin-bottom: 21px;
  cursor: pointer;
  // float: right;
}
.detail-back-img {
  width: 16px;
  height: 17px;
  margin-right: 8px;
}
.detail-back-text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.detail-top {
  width: 100%;
}
.detail-top-left-shu {
  width: 4px;
  height: 16px;
  background: #1890ff;
  border-radius: 2px;
  margin-right: 8px;
}
.detail-top-left-text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #28282c;
}
.detail-top-right-left {
  margin-right: 21px;
}
.detail-table {
  // padding: 52px 0px 0px 160px;
  padding: 52px 0px 0px 0px;
  width: 100%;
  height: 700px;
  overflow: auto;
}
.detail-table-content {
  width: max-content;
  border-top: 1px solid #e1e1ee;
  border-left: 1px solid #e1e1ee;
}
.detail-table-content-top {
}
.table-active {
  top: -32px;
  left: 30px;
  width: 20px;
  height: 20px;
}
.table-activeB {
  top: -32px;
  left: 47%;
  width: 20px;
  height: 20px;
}
.width80 {
  width: 80px;
}
// 表头

.detail-table-content-itemA {
  height: 120px;
  background: #fafafa;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  padding: 0px 10px;
  box-sizing: border-box;
}
.detail-table-content-itemA-con {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 18px;
}
.detail-table-content-itemB {
  height: 120px;
  background: #fafafa;
  box-sizing: border-box;
}
.detail-table-content-itemB-top {
  height: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 50px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
}
.detail-table-content-itemB-bottom-item {
  width: 80px;
  height: 70px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
}
.detail-table-content-itemB-bottom-item-text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 16px;
}
.detail-table-content-list-item {
  height: 40px;
  text-align: center;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
}
.detail-table-content-list:hover {
  background: rgb(230, 247, 255);
}
// .detail-table-content-list-item:nth-child(1) {
//   position: absolute;
//   left: 20px;
// }
// .detail-table-content-list-item:nth-child(2) {
//   position: absolute;
//   left: 100px;
// }
// 表头结束
</style>
